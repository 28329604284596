import React from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import { useNavigate } from "react-router-dom";
import { Event } from "../../../types/types";

import upcomingEvent01 from "../../../assets/images/upcomingEvents/CFN SINGAPORE 2024 - COMING SOON 1080x1350 (1).jpg";
import upcomingEvent02 from "../../../assets/images/upcomingEvents/CFN5 DUBAI 2024 - COMING SOON 1080x1350 (1).jpg";
import banner3 from "../../../assets/images/banners/CFN OFF2 SINGAPORE - TEASER 1920x1080(2).jpg";

interface UpcomingEventsWrapperProps {
  onEventSelect: (event: Event) => void;
}

const UpcomingEventsWrapper: React.FC<UpcomingEventsWrapperProps> = ({
  onEventSelect,
}) => {
  const navigate = useNavigate();

  const handleClick = (event: Event) => {
    onEventSelect(event);
    navigate("/upcoming-events", { state: { eventId: event.id } });
  };

  return (
    <div id="upcoming-events-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={"UPCOMING EVENTS"}
          titleColor={"white"}
          mainTitle={false}
        />
      </div>
      <div className="upcoming-events-wrapper">
        <img
          src={upcomingEvent01}
          alt="Upcoming Event 01"
          onClick={() =>
            handleClick({
              id: "1",
              title: "CFN SINGAPORE 2024",
              subTitle: "SINGAPORE | 19 SEP 2024",
              videoUrls: [],
              img: banner3,
              bannerUrl: banner3,
            })
          }
        />
        <img
          src={upcomingEvent02}
          alt="Upcoming Event 02"
          onClick={() =>
            handleClick({
              id: "2",
              title: "CFN 5",
              subTitle: "DUBAI | 24 DEC 2024",
              videoUrls: [],
              img: upcomingEvent02,
              bannerUrl: upcomingEvent02,
            })
          }
        />
      </div>
    </div>
  );
};

export default UpcomingEventsWrapper;
