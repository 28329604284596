import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import logoCFN from "../../../assets/icons/logo.svg";

const NavBarTest: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const element = document.querySelector("#nav-bar-landing-page");
      if (window.innerWidth > 768) {
        element?.classList.remove("open");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleTriggerClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleDropdownClick = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleNavLinkClick = (eventId?: string) => {
    if (eventId) {
      navigate("/upcoming-events", { state: { eventId } });
    }
    setDropdownOpen(false);
    setIsOpen(false);
  };

  return (
    <div
      id="nav-bar-landing-page"
      className={`${isScrolled ? "scrolled" : ""} ${isOpen ? "open" : ""}`}
    >
      <div className="logo-container" onClick={() => navigate("/")}>
        <img src={logoCFN} alt="CFN Logo" />
      </div>
      <div className="button-container-nav-bar">
        <ul className="nav">
          <li className="dropdown">
            <a href="#!" onClick={handleDropdownClick}>
              Events
            </a>
            <ul className={`dropdown-content ${isScrolled ? "scrolled" : ""}`}>
              <li>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavLinkClick("1");
                  }}
                >
                  CFN OffChain 2 - Singapore
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavLinkClick("2");
                  }}
                >
                  CFN 5 - Dubai
                </a>
              </li>
              <li>
                <a href="/watch-past-event">Past Events</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/watch-past-event">Live Stream</a>
          </li>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/press-release">Press Release</a>
          </li>
        </ul>
      </div>
      <div className="trigger" onClick={handleTriggerClick}></div>
    </div>
  );
};

export default NavBarTest;
